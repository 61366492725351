export interface JobTitlesResponse {
  job_title_levels: JobTitleLevel[];
  levels: ILevel[];
}

export interface LevelsResponse {
  levels: ILevel[];
}

export interface ILevel {
  id: number | null;
  name: string;
  order: number;
}

export interface JobTitleLevel {
  job_title: string;
  level: number | null;
}

export interface JobTitleLevelsSubmitBody {
  job_title_levels: JobTitleLevel[];
}

export interface PipelineResponse {
  filters: PipelineFilter[];
}

export interface PipelineFilter {
  label: string;
  levels: PipelineLevel[];
}
export interface PipelineLevel {
  label: string;
  order: number;
  count: number;
  groups: PipelineGroup[];
}

export interface PipelineGroup {
  label: string;
  count: number;
}

export enum ErrorCodes {
  "NoJobLevel" = "JOB_TITLE_LEVEL_NOT_FOUND",
  "IntegrationNotFound" = "INTEGRATION_NOT_FOUND",
}
