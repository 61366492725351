import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, Typography } from "@mui/material";
import { FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../components/ui/Button/Button";
import { Card } from "../components/ui/Card/Card";
import { Input } from "../components/ui/Input/Input";
import { Logo } from "../components/ui/Logo";
import { Paper } from "../components/ui/Paper/Paper";
import { WarningIcon } from "../components/ui/icons/WarningIcon";
import { useDemoAuth } from "../hooks/useDemoAuth";
import useTranslation from "../hooks/useTranslation";
import User from "../models/user";
import Login from "../styles/illustrations/Login.gif";
import { IUser } from "../types/user";
import { USER } from "../utils/api.utils";
import { PublicRoutes } from "../utils/routes.utils";
import "./LoginPage.scss";

export const DemoLoginPage = () => {
  const { t } = useTranslation("loginPage");
  const { onDemoLogin, loadingDemoAuth } = useDemoAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [errMsg, setErrMsg] = useState<string>();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setErrMsg("");
  }, [email, password]);

  const handleLogin = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onDemoLogin(email, password)
      .then(() => {
        const userStorage = localStorage.getItem(USER);
        if (!userStorage) {
          navigate(PublicRoutes.LOGIN);
          return;
        }

        const userJSON: IUser = JSON.parse(userStorage);
        const user = new User(userJSON);
        if (user.getCompanyName()) {
          const origin = location.state?.from?.pathname || "/dashboard";
          navigate(origin);
        } else {
          navigate(PublicRoutes.SIGNUP);
        }
      })
      .catch(() => {
        setErrMsg(`${t("error")}`);
      });
  };

  return (
    <div className="LoginPage">
      <div className="LoginPage__header">
        <Logo />
      </div>

      <div className="LoginPage__content">
        <div className="LoginPage__content__background">
          <img className="LoginPage__content__image" src={Login} alt="" />
        </div>
        <Card
          className="LoginPage__content__login"
          containerClassName="LoginPage__content__loginContainer"
        >
          <form
            className="LoginPage__content__login__form"
            onSubmit={(e) => handleLogin(e)}
          >
            <Typography
              variant="h1"
              sx={{ pb: 3 }}
              className="LoginPage__content__heading"
            >
              {t("signIn")}
            </Typography>
            <Input
              className="LoginPage__content__login__form__input"
              type="text"
              id="email"
              data-testid="email"
              autoComplete="off"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              disabled={loadingDemoAuth}
              required
              label={t("email")}
              htmlFor="email"
            />

            <Input
              className="LoginPage__content__login__form__input"
              type={showPassword ? "text" : "password"}
              id="password"
              data-testid="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              disabled={loadingDemoAuth}
              required
              label={t("password")}
              htmlFor="password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {errMsg && (
              <Paper
                showError
                className="LoginPage__content__login__form__error"
              >
                <WarningIcon />
                <Typography>{errMsg}</Typography>
              </Paper>
            )}
            <Button
              type="submit"
              color="info"
              variant="contained"
              data-testid="login-button"
              loading={loadingDemoAuth}
              disabled={loadingDemoAuth}
            >
              {t("logIn")}
            </Button>
          </form>
        </Card>
      </div>
    </div>
  );
};
