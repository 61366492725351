import { DataFilter } from "./filter";

export interface GetDiversityRatioResponse {
  employee_count: number;
  filters: DiversityRatioFilter[];
}

export interface DiversityRatioGroup {
  label: string;
  count: number;
}

export interface DiversityRatioFilter extends DataFilter<DiversityRatioGroup> {
  breakdowns: DiversityRatioBreakdown[];
}

export interface DiversityRatioBreakdown {
  label: string;
  filters: DiversityRatioBreakdownFilter[];
}

export interface DiversityRatioBreakdownFilter {
  label: string;
  count: number;
  groups: DiversityRatioGroup[];
}

export interface GetDiversityRecommendationsResponse {
  ethnicity_recommendations: string;
  age_recommendations: string;
  gender_recommendations: string;
}

export enum ErrorCodes {
  "IntegrationNotFound" = "INTEGRATION_NOT_FOUND",
}
