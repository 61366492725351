import { Grid } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { DiversityRatioService } from "../api/DiversityRatioService";
import { DiversityRatioOverview } from "../components/DiversityRatioOverview/DiversityRatioOverview";
import { ErrorCodes, GetDiversityRatioResponse } from "../types/diversityRatio";
import { ErrorResponse } from "../types/error";
import {
  generateDiversityBreakdown,
  generateDiversityFilters,
  getDiversityRatioGroupColor,
} from "../utils/DiversityRatio.utils";

export const DiversityRatioPage = () => {
  const diversityRatioService = new DiversityRatioService();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [errorCode, setErrorCode] = useState<string>("");

  const {
    data: diversityRatioData,
    isLoading,
    error,
  } = useQuery<GetDiversityRatioResponse, AxiosError>(
    ["diversityRatio"],
    () => diversityRatioService.getDiversityRatio(),
    { cacheTime: 60 * 60 * 1000 } // increase cache time to 60 minutes
  );

  const diversityFilters =
    !error && diversityRatioData
      ? generateDiversityFilters(diversityRatioData)
      : undefined;

  const diversityBreakdown =
    !error && diversityRatioData
      ? generateDiversityBreakdown(diversityRatioData.filters[selectedTabIndex])
      : undefined;

  const diversityBreakdownGroupColors =
    !error && diversityRatioData
      ? getDiversityRatioGroupColor(
          diversityRatioData.filters[selectedTabIndex]
        )
      : undefined;

  useEffect(() => {
    const errorData = error?.response?.data as ErrorResponse<ErrorCodes>;

    setErrorCode(errorData?.extra?.code || "");
  }, [error]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <DiversityRatioOverview
          isLoading={isLoading}
          error={!!error}
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
          diversityFilters={diversityFilters}
          noIntegration={errorCode === ErrorCodes.IntegrationNotFound}
          diversityBreakdown={diversityBreakdown}
          diversityBreakdownGroupColors={diversityBreakdownGroupColors}
        />
      </Grid>
    </Grid>
  );
};
